<template>
  <div
    v-if="
      $route.path !== '/views/Landing' &&
        $route.path !== '/menu/login' &&
        $route.path !== '/views/ventas/imprimir-factura' &&
        $route.path !==
          '/views/proveedores/imprimir-detalle-pedido-proveedor' &&
        $route.path !== '/views/pedidos/imprimir-detalle-pedido-cliente'
    "
  >
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
      <div
        class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3"
      >
        <div class="pt-1 mt-3" style="font-size:0.6rem;">
          Diseñado por
          <a href="https://www.rodapro.es" target="_blank">
            <img
              src="/img/rodapro_logos/rodaproImagotipo.svg"
              weight="20"
              height="20"
              class="ml-1 mr-1 mb-1"
            />
          </a>
          © 2022
        </div>
        <div>
          <a
            href="https://www.rodapro.es"
            target="_blank"
            class="footer-link pt-3"
            >Sobre nosotros</a
          >
          <a
            href="https://www.rodapro.es/politica-de-privacidad"
            target="_blank"
            class="footer-link pt-3 ml-4"
            >Política de privacidad</a
          >
          <a
            href="https://www.rodapro.es/politica-de-cookies"
            target="_blank"
            class="footer-link pt-3 ml-4"
            >Política de cookies</a
          >
          <a
            href="/views/formularioContacto"
            target="_blank"
            class="footer-link pt-3 ml-4"
            >Contacto</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "app-layout-footer",

  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    }
  }
};
</script>
