<!--COMO ESTE DASHBOARD TIENE DIFERENTES LAYOUTS SON ESTOS LOS QUE HACEN LA FUNCION DE APP
EN ESTE CASO ESCOGIMOS EL lAYOUT2 (SRC/LAYOUT/LAYOUT2.VUE)
ASI PUES PARA ESCUCHAR EVENTOS Y PROPAGAR PROPS SE HARA DESDE ALLI
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style src="./style.scss" lang="scss"></style>

<script>
export default {
  name: 'app',
  metaInfo: {
    title: 'Index',
    titleTemplate: '%s - Rodapro',
  },

  updated() {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 1);
  },
};
</script>
