import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/MarcasyModelos",
    component: Layout2,
    children: [
      {
        path: "cuadro-marcas",
        component: () => import("@/views/MarcasyModelos/CuadroMarcas")
      },
      {
        path: "cuadro-modelos",
        component: () => import("@/views/MarcasyModelos/CuadroModelos")
      },
      {
        path: "detalle-marcas",
        component: () => import("@/views/MarcasyModelos/DetalleMarcas")
      },
      {
        path: "detalle-modelos",
        component: () => import("@/views/MarcasyModelos/DetalleModelos")
      }
    ]
  }
];
