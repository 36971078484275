import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

import dashboardsRoutes from "./inicio";
import stockRoutes from "./stock";
import marcasymodelosRoutes from "./marcasymodelos";
import pedidosRoutes from "./pedidos";
import proveedoresRoutes from "./proveedores";
import ventasRoutes from "./ventas";
import administracionRoutes from "./administracion";
import direccionRoutes from "./direccion";
import manualRoutes from "./manual";
import landingRoutes from "./landing";
import formularioContactoRoutes from "./formularioContacto";

Vue.use(Router);
Vue.use(Meta);

const ROUTES = [
  // Default route
  { path: "", redirect: "/views/Landing" }
]
  .concat(dashboardsRoutes)
  .concat(stockRoutes)
  .concat(marcasymodelosRoutes)
  .concat(pedidosRoutes)
  .concat(proveedoresRoutes)
  .concat(ventasRoutes)
  .concat(administracionRoutes)
  .concat(direccionRoutes)
  .concat(manualRoutes)
  .concat(landingRoutes)
  .concat(formularioContactoRoutes);

// 404 Not Found page
ROUTES.push({
  path: "*",
  component: NotFound
});

const router = new Router({
  //la base es el cuerpo de la ruta de acceso a cada pagina. Se establece en vue.config.js y
  //es muy importante entender que BASE_URL = publicPath, ya que a BASE_URL no se puede acceder directamente
  //modo historia nos evita el # en las urls
  base: process.env.BASE_URL,
  mode: "history",
  routes: ROUTES
});

/* Router.prototype.open = function(routeObject) {
  const { href } = router.resolve(routeObject);
  window.open(href, "_blank");
}; */

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector(".app-splash-screen");
  if (splashScreen) {
    splashScreen.style.opacity = 0;
    setTimeout(
      () =>
        splashScreen &&
        splashScreen.parentNode &&
        splashScreen.parentNode.removeChild(splashScreen),
      300
    );
  }

  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
