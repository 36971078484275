// Additional polyfills
import "custom-event-polyfill";
import "url-polyfill";

import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import Popper from "popper.js";
import App from "./App";
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import moment from "moment";

Vue.prototype.moment = moment;

import globals from "./globals";

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

// Global RTL flag
Vue.mixin({
  data: globals
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
