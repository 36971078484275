import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/manual",
    component: Layout2,
    children: [
      {
        path: "estructura",
        name: "estructura",
        component: () => import("@/views/ManualUsuario/Estructura")
      }
    ]
  }
];
