import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/direccion",
    component: Layout2,
    children: [
      {
        path: "control-direccion",
        name: "ControlDireccion",
        component: () => import("@/views/Direccion/ControlDireccion")
      }
    ]
  },
  {
    path: "/views/direccion",
    component: Layout2,
    children: [
      {
        path: "seguimiento-diario",
        name: "SeguimientoDiario",
        component: () => import("@/views/Direccion/SeguimientoDiario")
      }
    ]
  },
  {
    path: "/views/direccion",
    component: Layout2,
    children: [
      {
        path: "seguimiento-mensual",
        name: "SeguimientoMensual",
        component: () => import("@/views/Direccion/SeguimientoMensual")
      }
    ]
  }
];
