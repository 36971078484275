<!--ESTE ARCHIVO FUNCIONA COMO APP PUES AL EXISTIR DIFERENTES LAYOUTS EN LA PLANTILLA CREARON UNO PARA CADA CONFIGUIRACION-->
<template>
  <div class="layout-wrapper layout-2">
    <div class="layout-inner">
      <app-layout-sidenav />

      <div class="layout-container">
        <app-layout-navbar />

        <div class="layout-content">
          <div
            class="router-transitions container-fluid flex-grow-1 container-p-y"
          >
            <!--ESCUCHAMOS EL EVENTO EMITIDO POR InformeClientes LLAMADO infoCliente
          HACEMOS UN METODO AL QUE LE PASAMOS LOS DATOS QUE RECIBIMOS infoCliente(infoCliente){
          IGUALAMOS LA VARIABLE QUE QUEREMOS ENVIAR A LOS DATOS RECIBIDOS this.clientData = infoCliente; }
          ENVIAMOS LOS DATOS A FacturaciónCliente :cliente="clientData"-->
            <router-view
              @infoCliente="infoCliente"
              :cliente="clientData"
              v-bind="obtenerFecha()"
              :date="date"
              :day="day"
              :diaSemana="diaSemana"
              :day7minus="day7minus"
              :month="month"
              :monthMinus1="monthMinus1"
              :yearMinus1="yearMinus1"
              :year="year"
              :hour="hour"
            />
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";

export default {
  name: "app-layout-2",
  components: {
    "app-layout-navbar": LayoutNavbar,
    "app-layout-sidenav": LayoutSidenav,
    "app-layout-footer": LayoutFooter
  },
  data() {
    return {
      date: "",
      month: "",
      year: "",
      day: "",
      diaSemana: "",
      day7minus: "",
      yearMinus1: "",
      monthMinus1: "",
      hour: "",
      clientData: "",
      pedidosData: ""
    };
  },
  mounted() {
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);
  },

  beforeDestroy() {
    this.layoutHelpers.destroy();
  },

  methods: {
    // ESCUCHAMOS EL EVENTO infoCliente QUE SE PRODUCE EN InformeClientes, PASAMOS LOS DATOS RECIBIDOS COMO PARAMETRO Y
    // LOS IGUALAMOS A LA VARIABLE clientData QUE ENVIAREMOS A VentasCliente
    infoCliente(infoCliente) {
      this.clientData = infoCliente;
    },
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
    // FECHA
    // Función calculo de fecha actual
    obtenerFecha() {
      const dias = new Array(
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado"
      );
      const meses = new Array(
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      );
      const fecha = new Date();
      this.date = `Hoy es ${dias[fecha.getDay()]} , ${fecha.getDate()} de ${
        meses[fecha.getMonth()]
      } de ${fecha.getFullYear()}`;
      this.month = meses[fecha.getMonth()];
      this.year = fecha.getFullYear();
      this.day = fecha.getDate();
      this.diaSemana = dias[fecha.getDay()];
      this.day7minus = this.day - 7;
      this.yearMinus1 = this.year - 1;
      this.monthMinus1 = meses[fecha.getMonth() - 1];
      this.hour =
        fecha.getHours() +
        ":" +
        ((fecha.getMinutes() < 10 ? "0" : "") + fecha.getMinutes()) +
        ":" +
        ((fecha.getSeconds() < 10 ? "0" : "") + fecha.getSeconds());
    }
  }
};
</script>
