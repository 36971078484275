import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/Ventas",
    component: Layout2,
    children: [
      {
        path: "seguimiento-ventas",
        component: () => import("@/views/Ventas/SeguimientoVentas")
      },
      {
        path: "seguimiento-pedidos",
        component: () => import("@/views/Ventas/SeguimientoPedidos")
      },
      {
        path: "informe-clientes",
        component: () => import("@/views/Ventas/InformeClientes")
      },
      {
        path: "facturacion-cliente",
        component: () => import("@/views/Ventas/FacturacionCliente")
      },
      {
        path: "imprimir-factura",
        component: () => import("@/views/Ventas/ImprimirFacturas")
      },
      {
        path: "ventas-cliente",
        component: () => import("@/views/Ventas/VentasCliente")
      },
      {
        path: "tarifa-cliente",
        component: () => import("@/views/Ventas/TarifaCliente")
      },
      {
        path: "llamadas-cliente",
        component: () => import("@/views/Ventas/LlamadasCliente")
      },
      {
        path: "cuota-mercado",
        component: () => import("@/views/Ventas/CuotaMercado")
      },
      {
        path: "clientes-activos-municipio",
        component: () => import("@/views/Ventas/ClientesActivosMunicipio")
      },
      {
        path: "clientes-inactivos-municipio",
        component: () => import("@/views/Ventas/ClientesInactivosMunicipio")
      },
      {
        path: "clientes-fuera-plazo",
        component: () => import("@/views/Ventas/ClientesFueraPlazo")
      },
      {
        path: "ranking-clientes",
        component: () => import("@/views/Ventas/RankingClientes")
      },
      {
        path: "direccion-por-objetivos",
        component: () => import("@/views/Ventas/DireccionPorObjetivos")
      },
      {
        path: "comerciales",
        component: () => import("@/views/Ventas/Comerciales")
      },
      {
        path: "detalle-comerciales",
        component: () => import("@/views/Ventas/DetalleComerciales")
      },
      {
        path: "mapa-ventas",
        component: () => import("@/views/Ventas/MapaVentas")
      }
    ]
  }
];
("/views/ventas/imprimir-factura");
