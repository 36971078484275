import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/proveedores",
    component: Layout2,
    children: [
      {
        path: "pedidos-realizados",
        name: "pedidos-realizados",
        component: () => import("@/views/Proveedores/PedidosRealizados")
      },
      {
        path: "subir-archivos",
        name: "subir-archivos",
        component: () => import("@/views/Proveedores/SubirArchivos")
      },
      {
        path: "detalle-pedidos-proveedores",
        name: "detalle-pedidos-proveedores",
        component: () => import("@/views/Proveedores/DetallePedidosProveedores")
      },
      {
        path: "imprimir-detalle-pedido-proveedor",
        name: "imprimir-detalle-pedido-proveedor",
        component: () => import("@/views/Proveedores/ImprimirDetallePedido")
      }
    ]
  }
];
