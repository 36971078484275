import Layout2 from "@/layout/Layout2";

export default [
  {
    path: "/views/",
    component: Layout2,
    children: [
      {
        path: "formularioContacto",
        name: "formularioContacto",
        component: () => import("@/views/FormularioContacto")
      }
    ]
  }
];
